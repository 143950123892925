@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  scroll-behavior: smooth; }

body {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif; }

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 5rem; }
  @media (max-width: 768px) {
    .container {
      padding: 2rem; } }

.footer {
  text-align: center;
  padding: 2rem 0;
  font-size: 1.5rem;
  background-color: #ccc; }

.drawer {
  position: fixed;
  top: -20px;
  background-color: #04473d;
  height: calc(100% + 20px);
  width: 60vw;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(-200%);
  transition: transform 0.3s ease-in-out; }
  .drawer__list {
    list-style: none;
    margin-top: 3rem;
    width: 100%;
    text-align: center; }
    .drawer__list--link {
      margin: 2rem 0;
      padding: 1rem 0;
      transition: all 0.2s; }
      .drawer__list--link a {
        text-decoration: none;
        color: white;
        font-size: 2.5rem;
        padding: 1rem 0; }
      .drawer__list--link:hover {
        background-color: white; }
        .drawer__list--link:hover a {
          color: #333; }
  .drawer__social-media {
    font-size: 3rem; }
    .drawer__social-media i {
      margin: 0 1rem;
      color: white; }

.drawer.open {
  transform: translateX(0); }

.nav {
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  z-index: 10; }
  .nav__logo img {
    height: 100px;
    width: 100px; }
    .nav__logo img:hover {
      -webkit-filter: brightness(110%);
              filter: brightness(110%); }
    @media (max-width: 768px) {
      .nav__logo img {
        height: 60px;
        width: 60px; } }
  .nav i {
    font-size: 3rem;
    margin-right: 3rem;
    cursor: pointer;
    color: white; }

.nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  transition: all 0.3s ease-in-out; }

.nav ul {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  font-size: 2rem; }

.nav a {
  color: white;
  text-decoration: none;
  padding: 7px 15px;
  transition: all 0.3s ease-in-out; }

.nav.active {
  background-color: #222;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); }

.nav.active a {
  color: white; }

.nav.active .container {
  padding: 10px 0; }

.nav a.current,
.nav a:hover {
  color: #c0392b; }

.hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative; }
  .hero__title {
    font-size: 3rem; }

.gallery {
  -webkit-filter: brightness(30%);
          filter: brightness(30%);
  margin-top: 64px;
  width: 100%;
  height: 100%;
  grid-column: full-start / full-end;
  min-height: calc(100vh - 128px);
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  overflow: hidden; }
  @media only screen and (max-width: 37.5em) {
    .gallery {
      grid-gap: 0.5rem;
      gap: 0.5rem; } }
  .gallery__item--1 {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(https://images.unsplash.com/photo-1602525661773-c2f9406ff452?ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80);
    grid-row: 1 / span 2;
    grid-column: 1 / span 2; }
  .gallery__item--2 {
    background-image: url(https://live.staticflickr.com/65535/50653487697_91d41977e0_w.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: aqua;
    grid-row: 1 / span 1;
    grid-column: 3 / span 3; }
  .gallery__item--3 {
    background-image: url(https://live.staticflickr.com/65535/50654534531_85752db29d.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: aquamarine;
    grid-row: 1 / span 4;
    grid-column: 6 / span 2; }
  .gallery__item--4 {
    background-color: bisque;
    grid-row: 1 / span 2;
    grid-column: 8 / span 2; }
  .gallery__item--5 {
    background-image: url(https://live.staticflickr.com/65535/50653486897_fd15acd4a4_k.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    grid-row: 3 / span 4;
    grid-column: 1 / span 2; }
  .gallery__item--6 {
    background-image: url(https://live.staticflickr.com/65535/50652659643_15b9487400_n.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: blue;
    grid-row: 4 / span 2;
    grid-column: 3 / span 2; }
  .gallery__item--7 {
    background-image: url(https://live.staticflickr.com/65535/50653396591_811b36523f_n.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: blueviolet;
    grid-row: 4 / 5;
    grid-column: 5 / 6; }
  .gallery__item--8 {
    background-color: brown;
    grid-row: 3 / span 2;
    grid-column: 8 / span 2; }
  .gallery__item--9 {
    background-color: cadetblue;
    grid-row: 3 / span 3; }
  .gallery__item--10 {
    background-image: url(https://live.staticflickr.com/65535/50652660348_347d4ef929_w.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: chartreuse;
    grid-row: 7 / span 2;
    grid-column: 1 / span 3; }
  .gallery__item--11 {
    background-image: url(https://live.staticflickr.com/65535/50653397391_97a91cf512_w.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: chocolate;
    grid-row: 6 / span 1;
    grid-column: 3 / span 3; }
  .gallery__item--12 {
    background-image: url(https://live.staticflickr.com/65535/50653396541_981c15c2ee_k.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-color: coral;
    grid-row: 5 / span 4;
    grid-column: 6 / span 2; }
  .gallery__item--13 {
    background-color: darkgreen;
    background-image: url(https://live.staticflickr.com/65535/50652659293_73d0cabe9d_n.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    grid-row: 5 / span 1;
    grid-column: 8 / span 3; }
  .gallery__item--14 {
    background-color: darkolivegreen;
    grid-row: 6 / span 2; }
  .gallery__img {
    object-fit: cover;
    display: block; }

.about {
  background-color: #222;
  color: white;
  padding-bottom: 10rem; }
  .about__header {
    font-size: 5rem;
    padding: 5rem 0;
    text-align: center;
    color: white; }
  .about__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .about__card {
    background-color: #04473d;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    margin: 2rem;
    padding: 2rem 4rem;
    border-radius: 10px;
    width: 325px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: transform 0.3s ease; }
    .about__card--img-container {
      width: 150px;
      height: 150px;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 2rem;
      position: relative;
      border: 3px solid #222;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); }
      @media (max-width: 480px) {
        .about__card--img-container {
          width: 120px;
          height: 120px; } }
    .about__card--img {
      width: 100%;
      height: auto;
      position: absolute; }
      .about__card--img-funky {
        top: -25px; }
    .about__card:hover {
      transform: scale(1.05); }
    .about__card--author {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 2.5rem; }
      @media (max-width: 480px) {
        .about__card--author {
          font-size: 1.5rem; } }
    .about__card--desc {
      font-size: 1.5rem;
      flex: 1 1; }
      @media (max-width: 480px) {
        .about__card--desc {
          font-size: 1.25rem; } }

.projects {
  background-color: #04473d;
  color: white; }
  .projects__header {
    font-size: 5rem;
    padding: 5rem 0;
    text-align: center; }
  .projects__info {
    padding: 0 20rem;
    font-size: 2rem;
    margin-bottom: 5rem;
    text-align: center; }
  .projects__category {
    font-size: 3rem;
    margin: 5rem 0;
    text-align: center; }

.gallery-container {
  margin-top: 10rem;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5rem;
  gap: 5rem;
  justify-content: center;
  background-color: #04473d;
  color: white; }
  @media (max-width: 1200px) {
    .gallery-container {
      grid-template-columns: -webkit-max-content;
      grid-template-columns: max-content;
      margin-top: 0rem; } }
  .gallery-container__gallery {
    width: 500px; }
    @media (max-width: 768px) {
      .gallery-container__gallery {
        width: 400px;
        margin: 0 auto; } }
    @media (max-width: 480px) {
      .gallery-container__gallery {
        width: 300px;
        margin: 0 auto; } }
    .gallery-container__gallery--overlays {
      display: flex;
      flex-direction: column;
      height: 75%;
      justify-content: space-between; }
    .gallery-container__gallery:last-child {
      margin: 20rem 0; }
  .gallery-container h1 {
    font-size: 3rem;
    text-align: center;
    margin: 3rem 0; }

.static-gallery__images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .static-gallery__images img {
    object-fit: contain;
    margin: 0.75rem; }
    @media (max-width: 480px) {
      .static-gallery__images img {
        width: 300px; } }
  .static-gallery__images--avatars2 img {
    width: 550px; }
    @media (max-width: 1200px) {
      .static-gallery__images--avatars2 img {
        display: flex; } }
    @media (max-width: 480px) {
      .static-gallery__images--avatars2 img {
        width: 300px; } }

.image-gallery-icon {
  color: #fff;
  transition: all 0.2s ease-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  -webkit-filter: drop-shadow(0 2px 2px #1a1a1a);
          filter: drop-shadow(0 2px 2px #1a1a1a); }
  @media (min-width: 768px) {
    .image-gallery-icon:hover {
      color: #337ab7; }
      .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1); } }
  .image-gallery-icon:focus {
    outline: 2px solid #337ab7; }

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none; }

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px; }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 36px;
    width: 36px; }
  @media (max-width: 768px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 15px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px; } }
  @media (max-width: 480px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 10px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px; } }

.image-gallery-fullscreen-button {
  right: 0; }

.image-gallery-play-button {
  left: 0; }

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%); }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px; }
  @media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 72px;
      width: 36px; } }
  @media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 48px;
      width: 24px; } }
  .image-gallery-left-nav[disabled],
  .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: 0.6;
    pointer-events: none; }

.image-gallery-left-nav {
  left: 0; }

.image-gallery-right-nav {
  right: 0; }

.image-gallery {
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative; }
  .image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5; }
    .image-gallery.fullscreen-modal .image-gallery-content {
      top: 50%;
      transform: translateY(-50%); }

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0; }
  .image-gallery-content.fullscreen {
    background: #000; }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 160px); }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh; }

.image-gallery-slide-wrapper {
  position: relative; }
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 110px); }
    @media (max-width: 768px) {
      .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
        width: calc(100% - 87px); } }
  .image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl; }

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center; }

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .image-gallery-slide.center {
    position: relative; }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain; }
  .image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    left: 5rem;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal; }
    @media (max-width: 768px) {
      .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: 0.8em;
        padding: 8px 15px; } }

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4; }
  .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center; }
  .image-gallery-bullets .image-gallery-bullet {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 0 #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: background 0.2s ease-out; }
    @media (max-width: 768px) {
      .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px; } }
    @media (max-width: 480px) {
      .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px; } }
    .image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
      background: #337ab7;
      transform: scale(1.1); }
    .image-gallery-bullets .image-gallery-bullet.active {
      background: #fff; }

.image-gallery-thumbnails-wrapper {
  position: relative; }
  .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        width: 81px; } }
    .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0; }
      .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0; }
        .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 5px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        margin: 0 3px; } }

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnails {
      padding: 3px 0; } }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform 0.45s ease-out;
    white-space: nowrap; }

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail {
      border: 3px solid transparent;
      width: 81px; } }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 2px; }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative; }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0; }
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #337ab7; }
    @media (max-width: 768px) {
      .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
        border: 3px solid #337ab7; } }

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail-label {
      font-size: 0.8em;
      line-height: 0.8em; } }

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4; }
  @media (max-width: 768px) {
    .image-gallery-index {
      font-size: 0.8em;
      padding: 5px 10px; } }

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #222;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  padding: 2rem; }
  .form-container__title {
    padding-top: 5rem;
    font-size: 5rem;
    color: white;
    margin-bottom: 4rem; }
  .form-container__subtitle {
    text-align: center;
    color: white;
    font-size: 2.5rem;
    margin-bottom: 2rem; }
    @media (max-width: 768px) {
      .form-container__subtitle {
        font-size: 2rem; } }
  .form-container__form {
    display: flex;
    flex-direction: column;
    align-items: center; }

.contact-form {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  width: 600px; }
  @media (max-width: 1200px) {
    .contact-form {
      width: 400px; } }
  @media (max-width: 480px) {
    .contact-form {
      width: 300px; } }
  .contact-form__group {
    margin-bottom: 2rem;
    transition: all 0.3s; }
    .contact-form__group:focus-within {
      transform: scale(1.1, 1.1); }
  .contact-form__label {
    font-size: 1.75em;
    padding: 5px 0;
    color: white;
    display: block;
    opacity: 0;
    transition: all 0.3s;
    transform: translateX(-50px); }
  .contact-form__control {
    box-shadow: none;
    border-radius: 0;
    border-color: #ccc;
    border-style: none none solid none;
    border-radius: 3px;
    width: 100%;
    font-size: 2.25em;
    transition: all 0.6s;
    padding: 1rem 2.5rem; }
    .contact-form__control::-webkit-input-placeholder {
      color: #aaa; }
    .contact-form__control:-ms-input-placeholder {
      color: #aaa; }
    .contact-form__control::-ms-input-placeholder {
      color: #aaa; }
    .contact-form__control::placeholder {
      color: #aaa; }
    .contact-form__control:focus {
      box-shadow: none;
      border-color: orange;
      outline: none; }
    .contact-form__control:invalid:focus {
      border-color: red; }
    .contact-form__control:valid:focus {
      border-color: green; }
  .contact-form__btn {
    background-color: #04473d;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 3em;
    padding: 10px 50px;
    text-transform: uppercase; }
    .contact-form__btn:hover {
      -webkit-filter: brightness(110%);
              filter: brightness(110%);
      cursor: pointer; }
  .contact-form__alert {
    font-size: 3rem; }

.focus {
  opacity: 1;
  transform: translateX(0px); }

textarea {
  resize: none; }

.sent {
  background-color: #04473d;
  color: #fff; }

.g-recaptcha {
  margin: 0 auto 2rem auto; }

.login-screen {
  height: 100vh;
  background-image: url("https://images2.alphacoders.com/106/1066635.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center; }

.login-form {
  height: 500px;
  width: 500px;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 2rem; }
  .login-form h2 {
    font-size: 3rem;
    color: red; }
  .login-form__group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .login-form__label {
    color: red;
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 1rem; }
  .login-form__control {
    width: 300px;
    font-family: inherit;
    margin-top: 1rem;
    color: red;
    padding: 1rem;
    background-color: black;
    border: 2px solid white;
    border-radius: 5px; }
    .login-form__control:focus {
      outline: none; }
  .login-form__btn {
    padding: 1rem 4rem;
    font-family: inherit;
    font-weight: bold;
    color: red;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 5px;
    transition: all 0.2s ease; }
    .login-form__btn:hover {
      background-color: white;
      color: black; }

